const icons = {
    "boFocus": "fa fa-fw fa-users",
    "donation": "fa fa-fw fa-credit-card",
    "embedCode": "fa fa-fw fa-code",
    "preVideo": "fa fa-fw fa-film",
    "ss": "fa fa-fw fa-desktop",
};

function generateStateDiagram(obj, pageName) {
    if (obj !== "Not Found") {
        var graphDefinition = "";
        if (pageName) {
            graphDefinition = `stateDiagram-v2\n`;
            obj.pageNodeDataArray.forEach((element) => {
                element.Name = element.Name.replace(":", "");
                graphDefinition += `state "<i class='${icons[element.pageType]}'></i><a href='https://beta.voicevoice.com/designer/conversation/${obj.conversationId}/page/${element.Id}' target='_blank'>${element.Name}</a>" as id${element.Id}\nid${element.Id} : ${element.Name !== pageName ? `<button data-pageid='${element.Id}' data-pagename='${element.Name}'>Zoom In ${element.Depth ? `+(${element.Depth})` : ''} </button>` : ``} \n`;
            });
            obj.pageLinkDataArray.forEach((element) => {
                element.label = element.label.replace(":", "");
                graphDefinition += `id${element.pageId} --> id${element.targetPageId} : ${element.label}\n`;
            });
            return graphDefinition;
        } else {
            graphDefinition = `stateDiagram-v2\n`;
            obj.pageNodeDataArray.forEach((element) => {
                element.Name = element.Name.replace(":", "");
                graphDefinition += `state "<i class='${icons[element.pageType]}'></i><a href='https://beta.voicevoice.com/designer/conversation/${obj.conversationId}/page/${element.Id}' target='_blank'>${element.Name}</a>" as id${element.Id}\nid${element.Id} : <button data-pageid='${element.Id}' data-pagename='${element.Name}'>Zoom In ${element.Depth ? `+(${element.Depth})` : ''} </button>\n`;
            });
            obj.pageLinkDataArray.forEach((element) => {
                element.label = element.label.replace(":", "");
                graphDefinition += `id${element.pageId} --> id${element.targetPageId} : ${element.label}\n`;
            });
            return graphDefinition;
        }
    }
}

export { generateStateDiagram }