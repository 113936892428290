const obtainArrayPageRelated = (conversation, pageId) => {
  let newConversation = {
    conversationId: conversation.conversationId,
    pageLinkDataArray: [],
    pageNodeDataArray: [],
  };
  conversation.pageLinkDataArray.forEach((page) => {
    if (page.pageId === pageId || page.targetPageId === pageId) {
      newConversation.pageLinkDataArray.push(page);
    }
  });
  newConversation.pageLinkDataArray.forEach((element) => {
    const foundPage = newConversation.pageNodeDataArray.find(
      (button) => button.Id === element.pageId
    );
    const foundTargetPage = newConversation.pageNodeDataArray.find(
      (button) => button.Id === element.targetPageId
    );
    if (!foundPage) {
      newConversation.pageNodeDataArray.push({
        Id: element.pageId,
        Name: element.pageName,
        pageType: element.pageType,
        Depth: countDepth(conversation.pageLinkDataArray, element.pageId)
      });
    }
    if (!foundTargetPage) {
      newConversation.pageNodeDataArray.push({
        Id: element.targetPageId,
        Name: element.targetPageName,
        pageType: element.targetPageType,
        Depth: countDepth(conversation.pageLinkDataArray, element.targetPageId)
      });
    }
  });
  return newConversation;
};

function countDepth(conversation, pageId) {
  let count = 0;
  conversation.forEach((page) => {
    if (page.pageId === pageId || page.targetPageId === pageId) {
      count++;
    }
  });
  return count;
}

export { obtainArrayPageRelated };
