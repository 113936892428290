import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TopBar from "../components/TopBar";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import StateDiagram from "../components/StateDiagram";
import Select from "../components/Select";
import Footer from "../components/Footer";
import { removeUserSession, getUser } from "../utils/Common";
import { generateStateDiagram } from "../utils/GenerateDiagram";
import { obtainArrayPageRelated } from "../utils/ArrayProcess";

function Admin(props) {
  const user = getUser();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openNot, setOpenNot] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [conversation, setConversation] = useState(null);
  const [subDiagrams, setSubDiagrams] = useState([]);
  const [loadingState, setLoadingState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const handleSubDiagram = (e) => {
    let pageId = e.target.getAttribute("data-pageid");
    if (pageId) {
      pageId = parseInt(pageId);
      let newActions = obtainArrayPageRelated(subDiagrams[0].actions, pageId);
      let diagram = {
        name: e.target.getAttribute("data-pagename"),
        actions: newActions,
        diagram: generateStateDiagram(newActions, e.target.getAttribute("data-pagename")),
        active: true,
      };
      let localSubDiagrams = subDiagrams;
      localSubDiagrams[localSubDiagrams.length - 1].active = false;
      localSubDiagrams.push(diagram);
      setSubDiagrams([...localSubDiagrams]);
    }
  };
  const handleLogin = () => {
    removeUserSession();
    props.history.push("/");
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const toggleNot = () => {
    setOpenNot(!openNot);
  };
  const handleConversation = (conver) => {
    setSubDiagrams([]);
    setNotFound(false);
    if (conver) {
      setConversation(conver);
      setLoadingState(true);
    } else {
      setConversation(conver);
      setLoadingState(conver);
    }
  };
  const cutArray = (pageName) => {
    const indice = subDiagrams.findIndex(element => element.name === pageName);
    const newSubDiagram = subDiagrams.slice(0, indice + 1);
    newSubDiagram[newSubDiagram.length - 1].active = true;
    setSubDiagrams(newSubDiagram);
  }
  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/conversations`
      );
      const conversationRes = await response.json();
      if (active) {
        setLoading(false);
        setConversations(
          Object.keys(conversationRes).map((key) => conversationRes[key])
        );
      }
    })();
    return () => {
      active = false;
    };
  }, [loading]);
  useEffect(() => {
    if (conversation && subDiagrams.length === 0) {
      (async () => {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/buttons/${conversation.id}`
        );
        const actions = await response.json();
        if (actions !== "Not Found") {
          let diagram = {
            name: conversation.title,
            actions,
            diagram: generateStateDiagram(actions),
            active: true,
          };
          let localSubDiagrams = subDiagrams;
          localSubDiagrams.push(diagram);
          setSubDiagrams(localSubDiagrams);
          setLoadingState(false);
        } else {
          setNotFound(true);
          setLoadingState(false);
        }
      })();
    }
  }, [conversation, subDiagrams]);
  console.log(subDiagrams);
  return (
    <div className={classes.root}>
      <TopBar
        open={open}
        toggleDrawer={toggleDrawer}
        openNot={openNot}
        toggleNot={toggleNot}
        handleLogin={handleLogin}
      />
      <main className={classes.content}>
        <div className={classes.drawerHeader} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid className={classes.gridContainer}>
            {/* Aqui va el switch con las rutas y aca se colocaran las paginas (Aclople de componentes al layout)*/}
            <Typography variant="h6" gutterBottom>
              Welcome {user.name}
            </Typography>
            <Grid item xs={12} align="center">
              <Select
                options={conversations}
                loading={loading}
                handleConversation={handleConversation}
              />
              {subDiagrams.length > 0 && (
                <Breadcrumbs aria-label="breadcrumb">
                  {subDiagrams.map((element, indice) =>
                    element.active ? (
                      <Typography key={indice} color="textPrimary">
                        {element.name}
                      </Typography>
                    ) : (
                        <span key={indice} style={{ cursor: "pointer" }} onClick={() => {
                          cutArray(element.name);
                        }}>
                          {element.name}
                        </span>
                      )
                  )}
                </Breadcrumbs>
              )}
            </Grid>
            <Grid item xs={12}>
              {
                notFound ? <>Not Found</> : <StateDiagram
                  handleSubDiagram={handleSubDiagram}
                  graphDefinition={
                    subDiagrams.length > 0
                      ? subDiagrams[subDiagrams.length - 1].diagram
                      : null
                  }
                  loading={loadingState}
                />
              }

            </Grid>
          </Grid>
          <Box pt={4}>
            <Footer />
          </Box>
        </Container>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below Admin bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  gridContainer: {
    textAlign: "center",
  },
}));

export default Admin;
