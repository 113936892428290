import React, { useEffect } from "react";
import "../assets/css/viewer.css";
import mermaid from "mermaid";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function StateDiagram({
  handleSubDiagram,
  graphDefinition,
  loading,
}) {
  var config = {
    securityLevel: "loose",
  };
  mermaid.initialize(config);
  useEffect(() => {
    try {
      let container = document.querySelector("#png-container");
      if (container) {
        mermaid.parse(graphDefinition);
        mermaid.render("theGraph", graphDefinition, function (svgCode) {
          container.innerHTML = svgCode;
          container.addEventListener("click", handleSubDiagram, false);
        });
      }
    } catch (err) {
      console.error(err);
    }
    return () => {
      let container = document.querySelector("#png-container");
      if (container) {
        container.removeEventListener("click", handleSubDiagram, false);
      }
    }
  });
  if (loading !== null) {
    if (loading) {
      return (
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <CircularProgress size={200} />
        </div>
      );
    } else {
      return (
        <>
          <div id="png-container" style={{ width: "100%", height: "450px", overflow: "scroll" }}>
          </div>
        </>
      );
    }
  } else {
    return null;
  }
}