import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Container from "@material-ui/core/Container";
import Footer from "../components/Footer";
import Logo from "../assets/images/voicevoice.png";
import { setUserSession } from "../utils/Common";
import CircularProgress from "@material-ui/core/CircularProgress";
import jwt from "jsonwebtoken";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {
  const classes = useStyles();
  const username = useFormInput("");
  const password = useFormInput("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleLogin = () => {
    setError(null);
    setLoading(true);
    var url = `${process.env.REACT_APP_API_URL}/users/signin`;
    var token = jwt.sign(
      {
        username: username.value,
        password: password.value,
      },
      process.env.REACT_APP_JWT_SECRET,
      {
        expiresIn: 60 * 60 * 24,
      }
    );
    fetch(url, {
      method: "POST",
      body: JSON.stringify({ tk: token }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        (response) => {
          if (response.error) {
            setError(response);
            setLoading(false);
          } else {
            setLoading(false);
            setUserSession(response.token, response.user);
            props.history.push("/admin");
          }
        },
        (error) => {
          setLoading(false);
          console.log("other error", error);
        }
      )
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
        setError(error);
      });
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img
          src={Logo}
          alt="Maestro Conference"
          style={{ width: "40%", height: "auto" }}
        />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            value={username.value}
            onChange={(e) => {
              username.onChange(e);
              setError(null);
            }}
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={password.value}
            onChange={(e) => {
              password.onChange(e);
              setError(null);
            }}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {error ? <Alert severity="error">{error.message}</Alert> : null}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleLogin}
          >
            Sign In
            {loading ? (
              <CircularProgress
                size={20}
                color="secondary"
                style={{ marginLeft: 15 }}
              />
            ) : null}
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Footer />
      </Box>
    </Container>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};
