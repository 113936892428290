import React, { useState, useEffect } from "react";
import "./assets/css/app.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SignIn from "./views/SignIn";
import Admin from "./views/Admin";
import PrivateRoute from "./utils/PrivateRoute";
import PublicRoute from "./utils/PublicRoute";
import NotFound from "./views/NotFound";
import { getToken, removeUserSession, setUserSession } from "./utils/Common";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#1D2734",
    },
  },
});

function App() {
  const [authLoading, setAuthLoading] = useState(true);
  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }
    fetch(`${process.env.REACT_APP_API_URL}/verifyToken?token=${token}`)
      .then((res) => res.json())
      .then((response) => {
        setUserSession(response.token, response.user);
        setAuthLoading(false);
      })
      .catch((error) => {
        removeUserSession();
        setAuthLoading(false);
      });
  }, []);
  if (authLoading && getToken()) {
    return <div className="content">Checking Authentication...</div>;
  }
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <PublicRoute exact path="/" component={SignIn} />
          <PrivateRoute exact path="/admin" component={Admin} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
