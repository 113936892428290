import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function Select({ options, loading, handleConversation }) {
  const matches = useMediaQuery("(min-width:600px)");
  let width = null;
  if (matches) {
    width = "500px";
  } else {
    width = "auto";
  }
  return (
    <Autocomplete
      id="select-conversation"
      style={{ width }}
      getOptionSelected={(option, value) => option.title === value.title}
      getOptionLabel={(option) =>
        `${option.stringId} (#${option.id}): ${option.title}`
      }
      onChange={(event, newValue) => {
        if (newValue) {
          handleConversation(newValue);
        } else {
          handleConversation(null);
        }
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select a conversation"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
